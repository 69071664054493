import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import { withPreview } from "gatsby-source-prismic";
import { createBreakpoint } from "react-use";
import { Helmet } from "react-helmet";

// SEO
import { SEO } from "../components/seo/seo";

// Context
import { ActiveListingCountry } from "../components/context/active-country";
import { PageTitleContext } from "../components/context/page-title";
import { PageTypeContext } from "../components/context/page-type";

// Components
import { Gallery } from "../components/images/gallery";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { OurFavouritesMap } from "../components/maps/our-favourites-map";
import { HouseDetails } from "../components/listing/house-details";
import { TopAvailabilityModule } from "../components/listing/top-availability-module";
import { Availability } from "../components/listing/availability";
import { FindOutMore } from "../components/listing/find-out-more";
import { ActiveListingCity } from "../components/context/active-city";
import { AlertIcon } from "../components/icons/alert";

const Page = styled.div`
  overflow-x: hidden;

  & a {
    text-decoration: none;
    border-bottom: 1px solid rgb(14 53 99 / 30%);
    transition: 250ms border-bottom ease;

    &:hover {
      border-bottom: 1px solid rgb(14 53 99);
    }
  }
`;

const Hero = styled.section`
  overflow: hidden;

  max-height: 700px;

  & img {
    max-height: 700px;
    object-fit: cover;
  }
`;

const Section = styled.section`
  padding: ${props => (props.xlPadding ? props.xlPadding : `100px 90px`)};

  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : `#fff`};
  background: ${props => props.background && props.background};

  @media (max-width: 1200px) {
    padding: ${props => (props.lPadding ? props.lPadding : `100px 60px`)};
  }

  @media (max-width: 1000px) {
    padding: 60px 40px;
    padding: ${props => (props.mPadding ? props.mPadding : `100px 40px`)};
  }

  @media (max-width: 768px) {
    padding: ${props => (props.sPadding ? props.sPadding : `95px 20px`)};
  }
`;

const ContentContainer = styled.div`
  // max-width: 1260px;
  // margin: 0 auto;

  & .heading,
  & .heading > p {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
      letter-spacing: 0.05em;
    }
  }
`;

const MasonryContainer = styled.div`
  .my-masonry-grid {
    display: flex;
    margin: 0 -30px;

    @media (max-width: 768px) {
      margin: 0 -20px;
    }
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
    position: relative;

    &:first-of-type {
      padding: 0 30px;
    }

    &:nth-of-type(2) {
      padding: 0 30px;

      &:before {
        content: "";
        display: block;
        height: 100%;
        width: 1px;

        background-image: linear-gradient(
          #0e3563 33%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: right;
        background-size: 1px 3px;
        background-repeat: repeat-y;

        // border-left: 2px solid rgba(208, 167, 144, 0.25);

        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &:nth-of-type(3) {
      padding: 0 30px;

      &:before {
        content: "";
        display: block;
        height: 100%;
        width: 1px;

        background-image: linear-gradient(
          #0e3563 33%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: right;
        background-size: 1px 3px;
        background-repeat: repeat-y;

        // border-left: 2px solid rgba(208, 167, 144, 0.25);

        position: absolute;
        top: 0;
        left: 0;
      }
    }

    // @media (max-width: 900px) {
    //   padding-left: 40px;
    // }

    // @media (max-width: 768px) {
    //   padding-left: 20px;
    // }
  }

  .my-masonry-grid_column > div {
    margin-bottom: 80px;
  }
`;

const TextColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 60px;

  & p {
    font-size: 21px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }

  & .small-text > p {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }

  @media (max-width: 1000px) {
    grid-gap: 40px;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const ThreeColumnText = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px 1fr 60px 1fr;
  grid-gap: 0;

  & p {
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.03em;

    margin: 0 0 13px 0;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.03em;
    }
  }

  & strong {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
    }
  }

  & .spacer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 768px) {
      display: block;

      padding: 20px 0;
    }

    & .line {
      background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 3px;
      background-repeat: repeat-y;

      height: 100%;
      width: 1px;

      @media (max-width: 768px) {
        height: 1px;
        width: 100%;

        background-image: linear-gradient(
          to right,
          #0e3563 33%,
          rgba(14, 53, 99, 0) 0%
        );

        background-position: bottom;
        background-size: 3px 1px;
        background-repeat: repeat-x;
      }
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 40px 1fr 40px 1fr;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const TopText = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 60px;

  & .listing-introduction {
    grid-column-start: 3;
    grid-column-end: 7;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1000px) {
    grid-gap: 40px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: unset;

    & .listing-introduction {
      order: 1;
      margin: 0 0 60px 0;
    }

    & .availability-module {
      order: 2;
    }
  }
`;

const FavouritesTitle = styled.div`
  margin: 0 0 120px 0;

  @media (max-width: 768px) {
    margin: 0 0 30px 0;
  }

  & .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 60px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  & p {
    margin: 0;
  }

  & .favourites-introduction-text {
    margin: 100px 0 0 0;

    grid-column-start: 2;
    grid-column-end: 4;

    @media (max-width: 768px) {
      margin: 30px 0 0 0;
    }

    & p {
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 0.03em;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  & .heading {
    grid-column: span 3;

    & p {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 0.05em;

      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 21px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Favourite = styled.div`
  position: relative;

  & .timing {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.35em;

    margin: 15px 0 0 0;
  }

  & .title {
    margin: 6px 0 0 0;

    & h2 {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 0.05em;

      @media (max-width: 768px) {
        font-size: 25px;
        line-height: 28px;
      }
    }
  }

  & .caption {
    & p {
      margin: 10px 0 0 0;
    }
  }

  & a {
    text-decoration: none;
    border-bottom: 1px solid rgb(14 53 99 / 30%);
    transition: 250ms border-bottom ease;

    &:hover {
      border-bottom: 1px solid rgb(14 53 99);
    }
  }
`;

const Credits = styled.div`
  & p:first-of-type {
    margin-top: 0;
  }
`;

const CountryLink = styled.h3`
  margin: 100px 0 0 0;

  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.05em;

  & a {
    text-decoration: none;
    border-bottom: 2px solid rgb(14 53 99);

    &:hover {
      border-bottom: 2px solid rgb(14 53 99);
    }
  }

  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.05em;
  }
`;

const Line = styled.hr`
  height: 1px;

  background-image: linear-gradient(
    to right,
    #0e3563 33%,
    rgba(14, 53, 99, 0) 0%
  );

  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;

  margin: 25px 0 0 0;
  padding: 0;
  border: 0;
`;

const Alert = styled.div`
  & .alert-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .line {
    background-image: linear-gradient(
      to right,
      #0e3563 33%,
      rgba(14, 53, 99, 0) 0%
    );

    background-position: top;
    background-size: 3px 1px;
    background-repeat: repeat-x;

    width: 100%;
    height: 1px;

    padding: 0 0 30px 0;
  }

  margin: 40px 0 0 0;

  & p {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;

    margin: 0 0 0 15px;

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
    }
  }

  @media (max-width: 768px) {
    padding: 30px 20px 0 20px;
  }

  @media (max-width: 650px) {
    align-items: flex-start;
  }
`;

const OurFavouritesMobile = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;

  overflow-x: auto;
  position: relative;

  padding: 0 0 20px 0;

  & .favourites-mobile-container {
    flex: 0 0 80%;
    padding: 0 20px;
    position: relative;
    display: flex;
    align-self: stretch;

    & .column {
      display: flex;
      flex-direction: column;
    }

    & .text {
      flex: 1 0 auto;
    }

    & img {
      height: 100%;
      object-fit: contain;
      object-position: left;
    }

    &::after {
      content: "";
      display: block;

      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;

      width: 1px;
      height: 100%;

      background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 3px;
      background-repeat: repeat-y;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
`;

// Breakpoints
const useBreakpoint = createBreakpoint({ XL: 768, L: 767, M: 601, S: 10 });

const Listing = ({ data }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [pageTitleContext, setPageTitleContext] = useContext(PageTitleContext);
  const [pageTypeContext, setPageTypeContext] = useContext(PageTypeContext);

  const [activeCountry, setActiveCountry] = useContext(ActiveListingCountry);
  const [activeCity, setActiveCity] = useContext(ActiveListingCity);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    setPageTitleContext(data.prismicListing.data.title.text);
    setPageTypeContext(`listing`);

    if (data.prismicListing.data.city.document !== null) {
      setActiveCity({
        name: data.prismicListing.data.city.document.data.name.text,
        url: data.prismicListing.data.city.document.url,
      });
    }

    if (data.prismicListing.data.country.document !== null) {
      setActiveCountry({
        name: data.prismicListing.data.country.document.data.name.text,
        url: data.prismicListing.data.country.document.url,
      });
    }
  }, [data, setPageTitleContext, setPageTypeContext, setActiveCountry]);

  const listingTextColumns = data.prismicListing.data.text_columns.map(
    (content, index) => (
      <React.Fragment key={`listing_text_column_${index}`}>
        {index !== 0 && (
          <div className="spacer">
            <div className="line" />
          </div>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: content.column_text.html,
          }}
        />
      </React.Fragment>
    )
  );

  const listingFavourites = data.prismicListing.data.favourites.map(
    (content, index) => (
      <Favourite key={`listing_favourite_${index}`}>
        {content.thumbnail1.fluid !== null && (
          <img
            src={content.thumbnail1.fluid.srcWebp}
            srcSet={content.thumbnail1.fluid.srcSetWebp}
            alt={content.thumbnail1.alt}
            loading="lazy"
          />
        )}
        <p className="transcript caps timing">{content.timing}</p>
        <div
          className="transcript title"
          dangerouslySetInnerHTML={{
            __html: content.favourite_title.html,
          }}
        />
        <div
          className="caption"
          dangerouslySetInnerHTML={{
            __html: content.caption.html,
          }}
        />
      </Favourite>
    )
  );

  const listingFavouritesMobile = data.prismicListing.data.favourites.map(
    (content, index) => (
      <Favourite
        key={`listing_favourite_${index}`}
        className="favourites-mobile-container"
      >
        <div className="column">
          {content.thumbnail1.fluid !== null && (
            <AspectRatioImageContainer
              image={null}
              padding={66.667}
              bgColor={`transparent`}
            >
              <img
                src={content.thumbnail1.fluid.srcWebp}
                srcSet={content.thumbnail1.fluid.srcSetWebp}
                alt={content.thumbnail1.alt}
                loading="lazy"
              />
            </AspectRatioImageContainer>
          )}
          <div className="text">
            <p className="transcript caps timing">{content.timing}</p>
            <div
              className="transcript title"
              dangerouslySetInnerHTML={{
                __html: content.favourite_title.html,
              }}
            />
            <div
              className="caption"
              dangerouslySetInnerHTML={{
                __html: content.caption.html,
              }}
            />
          </div>
        </div>
      </Favourite>
    )
  );

  // https://developers.brewerdigitalmarketing.com/generator/hotel/room
  const listingSchema = {
    "@context": "http://schema.org",
    "@type": ["HotelRoom"],
    name: data.prismicListing.data.title.text,
    url: "https://hour.directory",
    image:
      data.prismicListing.data.thumbnail.fluid !== null
        ? data.prismicListing.data.thumbnail.fluid.src
        : ``,
    description: data.prismicListing.data.text.text,
    bed: {
      "@type": "BedDetails",
      numberOfBeds: data.prismicListing.data.bedrooms,
    },
    occupancy: {
      "@type": "QuantitativeValue",
      maxValue: data.prismicListing.data.sleeps,
      unitCode: "C62",
    },
    yearBuilt: data.prismicListing.data.year_built,
    geo: {
      "@type": "GeoCoordinates",
      latitude:
        data.prismicListing.data.location.latitude !== undefined &&
        data.prismicListing.data.location.latitude !== null
          ? data.prismicListing.data.location.latitude
          : ``,
      longitude:
        data.prismicListing.data.location.longitude !== undefined &&
        data.prismicListing.data.location.longitude !== null
          ? data.prismicListing.data.location.longitude
          : ``,
    },
  };

  return (
    <>
      <SEO
        seoTitle={data.prismicListing.data.title.text}
        seoText={data.prismicListing.data.text.text}
        seoImage={
          data.prismicListing.data.thumbnail.fluid !== null
            ? data.prismicListing.data.thumbnail.fluid.src
            : ``
        }
      />
      <Helmet encodeSpecialCharacters={false}>
        <script type="application/ld+json">
          {JSON.stringify(listingSchema)}
        </script>
      </Helmet>
      <Page>
        <Hero>
          {data.prismicListing.data.hero_image.fluid !== null && (
            <AspectRatioImageContainer
              image={data.prismicListing.data.hero_image}
            >
              <img
                srcSet={data.prismicListing.data.hero_image.fluid.srcSetWebp}
                src={data.prismicListing.data.hero_image.fluid.srcWebp}
                alt={data.prismicListing.data.hero_image.alt}
                loading="lazy"
              />
            </AspectRatioImageContainer>
          )}
          <p>
            <em>{data.prismicListing.data.hero_image.alt}</em>
          </p>
        </Hero>

        <Section
          xlPadding={`100px 90px 150px`}
          lPadding={`100px 60px 150px`}
          mPadding={`60px 40px 70px`}
          sPadding={`60px 20px 70px`}
        >
          <ContentContainer>
            <TopText>
              <TopAvailabilityModule data={data} />

              <div
                className="listing-introduction text-28-crimson"
                dangerouslySetInnerHTML={{
                  __html: data.prismicListing.data.text.html,
                }}
              />
            </TopText>
          </ContentContainer>
        </Section>

        <Section
          xlPadding={`0 90px 150px`}
          lPadding={`0 60px 150px`}
          mPadding={`0 40px`}
          sPadding={`0 20px`}
        >
          <ContentContainer>
            <ThreeColumnText>{listingTextColumns}</ThreeColumnText>
          </ContentContainer>
        </Section>

        <Section
          xlPadding={`0 90px 80px`}
          lPadding={`0 60px 80px`}
          mPadding={`75px 40px 70px`}
          sPadding={`75px 20px 70px`}
        >
          <ContentContainer>
            <Gallery images={data.prismicListing.data.images} />
          </ContentContainer>
        </Section>

        <Section
          backgroundColor={`#EEEEEE`}
          xlPadding={`17px 90px 60px`}
          lPadding={`17px 60px 60px`}
          mPadding={`17px 40px 65px`}
          sPadding={`17px 20px 65px`}
        >
          <ContentContainer>
            <HouseDetails data={data.prismicListing.data} />
          </ContentContainer>
        </Section>

        <Section
          backgroundColor={
            data.prismicListing.data.background_color_override !== null
              ? data.prismicListing.data.background_color_override
              : `#FFF3EC`
          }
          xlPadding={`40px 90px 100px`}
          lPadding={`40px 60px 100px`}
          mPadding={`17px 0 50px`}
          sPadding={`17px 0 50px`}
        >
          <ContentContainer>
            <FavouritesTitle>
              <div className="grid">
                <div
                  className="heading"
                  dangerouslySetInnerHTML={{
                    __html: data.prismicListing.data.text_column_left.html,
                  }}
                />

                <div
                  className="favourites-introduction-text"
                  dangerouslySetInnerHTML={{
                    __html: data.prismicListing.data.text_column_right.html,
                  }}
                />
              </div>
            </FavouritesTitle>

            {breakpoint === "XL" && (
              <MasonryContainer>
                <Masonry
                  breakpointCols={3}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {listingFavourites}
                </Masonry>
              </MasonryContainer>
            )}

            {(breakpoint === "L" ||
              breakpoint === "M" ||
              breakpoint === "S") && (
              <OurFavouritesMobile>
                {listingFavouritesMobile}
              </OurFavouritesMobile>
            )}

            {data.prismicGlobalSettings.data.display_covid_alert && (
              <Alert>
                <div className="line" />
                <div className="alert-container">
                  <AlertIcon />
                  <div
                    className="transcript"
                    dangerouslySetInnerHTML={{
                      __html: data.prismicGlobalSettings.data.covid_text.html,
                    }}
                  />
                </div>
              </Alert>
            )}
          </ContentContainer>
        </Section>

        <Section
          xlPadding={`40px 90px 140px`}
          lPadding={`40px 60px 140px`}
          mPadding={`17px 40px 100px`}
          sPadding={`17px 20px 100px`}
        >
          <ContentContainer>
            <h3 className="transcript heading">
              The house and surrounding area
            </h3>
            <OurFavouritesMap
              favourites={data.prismicListing.data.favourites}
              listingLocation={data.prismicListing.data.location}
              listingTitle={data.prismicListing.data.title.text}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
              breakpoint={breakpoint}
            />
          </ContentContainer>
        </Section>

        <Section
          backgroundColor={`#F9F9F9`}
          xlPadding={`70px 90px 150px`}
          lPadding={`70px 60px 150px`}
          mPadding={`20px 40px 55px`}
          sPadding={`20px 20px 55px`}
        >
          <ContentContainer>
            <TextColumns>
              <Availability
                gridSpanDesktop={1}
                startDate={data.prismicListing.data.start_date}
                endDate={data.prismicListing.data.end_date}
                price={data.prismicListing.data.price}
                link={data.prismicListing.data.booking_link.url}
                currency={
                  data.prismicListing.data.country.document.data.currency_code
                }
                availabilityText={
                  data.prismicListing.data.availability_text.text
                }
              />

              <FindOutMore
                gridSpanDesktop={1}
                ownersWebsiteLink={
                  data.prismicListing.data.owners_website_link.url
                }
                availabilityLink={
                  data.prismicListing.data.availability_link.url
                }
                bookingLink={data.prismicListing.data.booking_link.url}
              />

              <Credits
                className="small-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicListing.data.credits.html,
                }}
              />
            </TextColumns>

            <CountryLink className="transcript">
              View more places in{" "}
              <Link to={data.prismicListing.data.country.document.url}>
                {data.prismicListing.data.country.document.data.name.text}
              </Link>
            </CountryLink>

            <Line />
          </ContentContainer>
        </Section>
      </Page>
    </>
  );
};

export default withPreview(Listing);

export const query = graphql`
  query Listing($uid: String!) {
    prismicListing(uid: { eq: $uid }) {
      data {
        hero_image {
          alt
          fluid(
            imgixParams: { w: 2000 }
            srcSetBreakpoints: [200, 400, 800, 1200, 1600, 2000]
          ) {
            srcWebp
            srcSetWebp
            aspectRatio
            src
          }
          dimensions {
            width
            height
          }
        }
        thumbnail {
          alt
          fluid(
            imgixParams: { w: 2000 }
            srcSetBreakpoints: [200, 400, 800, 1200, 1600, 2000]
          ) {
            srcWebp
            srcSetWebp
            aspectRatio
            src
          }
          dimensions {
            width
            height
          }
        }
        title {
          html
          text
        }
        location {
          longitude
          latitude
        }
        is_listing_available
        start_date
        end_date
        price
        booking_link {
          url
        }
        availability_text {
          text
        }
        availability_link {
          url
        }
        city {
          document {
            ... on PrismicCity {
              id
              url
              data {
                name {
                  text
                }
              }
            }
          }
        }
        country {
          document {
            ... on PrismicCountry {
              id
              url
              data {
                name {
                  text
                }
                currency_code
              }
            }
          }
        }
        text {
          html
          text
        }
        text_columns {
          column_text {
            html
          }
        }
        images {
          image {
            alt
            fluid {
              srcSetWebp
              srcWebp
            }
            dimensions {
              width
              height
            }
          }
        }
        area {
          html
          text
        }
        on_the_doorstep {
          html
          text
        }
        nearest_village {
          html
          text
        }
        nearest_city {
          html
          text
        }
        directions {
          html
          text
        }
        additional_location_fields {
          field_title {
            html
            text
          }
          additional_location_field {
            html
          }
        }
        by_train {
          html
          text
        }
        train_to_house_transport {
          html
          text
        }
        by_car {
          html
          text
        }
        ev_hire {
          html
          text
        }
        ev_charging {
          html
          text
        }
        by_bicycle {
          html
          text
        }
        additional_journey_fields {
          field_title {
            html
            text
          }
          additional_journey_field {
            html
          }
        }
        sleeps
        bedrooms
        floors
        size {
          html
          text
        }
        notes {
          html
          text
        }
        architect {
          html
          text
        }
        year_built
        additional_house_fields {
          field_title {
            html
            text
          }
          additional_house_field {
            html
          }
        }
        price_text
        single_night_price
        additional_price_fields {
          field_title {
            html
            text
          }
          additional_price_field
        }
        to_book {
          html
          text
        }
        booking_availability_text {
          html
          text
        }
        rental_type {
          html
          text
        }
        owners_website_field {
          html
          text
        }
        additional_booking_fields {
          field_title {
            html
            text
          }
          additional_booking_field {
            html
          }
        }
        covid_policy {
          html
          text
        }
        further_information {
          html
          text
        }
        additional_policy_fields {
          field_title {
            html
            text
          }
          additional_policy_field {
            html
          }
        }
        press_fields {
          field_title {
            html
            text
          }
          press_link {
            html
          }
        }

        text_column_left {
          html
        }
        text_column_right {
          html
        }
        background_color_override
        favourites {
          thumbnail1 {
            fluid {
              srcWebp
              srcSetWebp
            }
            dimensions {
              width
              height
            }
            alt
          }
          timing
          favourite_title {
            html
            text
          }
          map_title
          caption {
            html
          }
          location1 {
            latitude
            longitude
          }
          display_on_favourites_map
        }
        owners_website_link {
          url
        }
        credits {
          html
        }
      }
    }
    prismicGlobalSettings {
      data {
        display_covid_alert
        covid_text {
          html
        }
      }
    }
  }
`;
