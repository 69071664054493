import React, { useState } from "react";
import styled from "styled-components";

// Components
import { DownArrowIcon } from "../icons/link-arrow";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 60px;

  & h3 {
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.05em;

    @media (max-width: 768px) {
      line-height: 21px;
      letter-spacing: 0.05em;
    }
  }

  & .text-row {
    display: grid;
    grid-template-columns: 220px 1fr;

    &:first-of-type {
      padding: 13px 0 6px 0;
    }

    &:last-of-type {
      padding-bottom: 13px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    line-height: 20px;
    padding: 0 0 6px 0;
  }

  & p {
    margin: 0;

    & a {
      text-decoration: none;
      border-bottom: 1px solid rgb(0 0 0 / 30%);
      transition: 250ms border-bottom ease;

      &:hover {
        border-bottom: 1px solid rgb(0 0 0);
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const Row = styled.div`
  grid-column-start: 3;
  grid-column-end: 7;

  padding: 20px 0;

  align-self: start;

  background-image: linear-gradient(
    to right,
    #0e3563 33%,
    rgba(14, 53, 99, 0) 0%
  );

  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;

  @media (max-width: 768px) {
    padding: 14px 0 12px 0;
  }
`;

const HouseDetailsTitle = styled.div`
  grid-column: span 2;
  margin: 20px 0 0 0;

  & p {
    margin: 0;

    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
    }
  }
  @media (max-width: 768px) {
    margin: 0 0 40px 0;
  }
`;

const Accordion = styled.div`
  height: auto;

  max-height: ${props => props.maxHeight};
  transition: 600ms max-height linear;

  overflow: hidden;

  & a {
    text-decoration: none;
    border-bottom: 1px solid rgb(255 255 255 / 30%);
    transition: 250ms border-bottom ease;

    &:hover {
      border-bottom: 1px solid rgb(255 255 255);
    }
  }
`;

const AccordionTitle = styled.div`
  & button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    width: 100%;

    border: 0;
    padding: 0;
    margin: 0;
  }
`;

const formatPrice = (price, currency) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currency,
    maximumSignificantDigits: Math.trunc(Math.abs(price)).toFixed().length,
  }).format(price);
};

export const HouseDetails = ({ data }) => {
  const [accordionValues, setAccordionValues] = useState({
    location: false,
    journey: false,
    house: false,
    prices: false,
    booking: false,
    policy: false,
    press: false,
  });

  const changeHandler = value => {
    setAccordionValues({ ...accordionValues, ...value });
  };

  const additionalBookingFields = data.additional_booking_fields
    .filter(content => content.field_title.text !== "")
    .map((content, index) => (
      <div key={`additional_booking_field_${index}`} className="text-row">
        <div
          dangerouslySetInnerHTML={{
            __html: content.field_title.html,
          }}
        />

        <div
          className="black-text"
          dangerouslySetInnerHTML={{
            __html: content.additional_booking_field.html,
          }}
        />
      </div>
    ));

  const additionalHouseFields = data.additional_house_fields
    .filter(content => content.field_title.text !== "")
    .map((content, index) => (
      <div key={`additional_house_fields_${index}`} className="text-row">
        <div
          dangerouslySetInnerHTML={{
            __html: content.field_title.html,
          }}
        />

        <div
          className="black-text"
          dangerouslySetInnerHTML={{
            __html: content.additional_house_field.html,
          }}
        />
      </div>
    ));

  const additionalJourneyFields = data.additional_journey_fields
    .filter(content => content.field_title.text !== "")
    .map((content, index) => (
      <div key={`additional_journey_fields_${index}`} className="text-row">
        <div
          dangerouslySetInnerHTML={{
            __html: content.field_title.html,
          }}
        />

        <div
          className="black-text"
          dangerouslySetInnerHTML={{
            __html: content.additional_journey_field.html,
          }}
        />
      </div>
    ));

  const additionalLocationFields = data.additional_location_fields
    .filter(content => content.field_title.text !== "")
    .map((content, index) => (
      <div key={`additional_location_fields_${index}`} className="text-row">
        <div
          dangerouslySetInnerHTML={{
            __html: content.field_title.html,
          }}
        />

        <div
          className="black-text"
          dangerouslySetInnerHTML={{
            __html: content.additional_location_field.html,
          }}
        />
      </div>
    ));

  const additionalPriceFields = data.additional_price_fields
    .filter(content => content.field_title.text !== "")
    .map((content, index) => (
      <div key={`additional_price_field_${index}`} className="text-row">
        <div
          dangerouslySetInnerHTML={{
            __html: content.field_title.html,
          }}
        />

        <div className="black-text">
          <p>
            {content.price_text}
            {formatPrice(
              content.additional_price_field,
              data.country.document.data.currency_code
            )}
          </p>
        </div>
      </div>
    ));

  const additionalPolicyFields = data.additional_policy_fields
    .filter(content => content.field_title.text !== "")
    .map((content, index) => (
      <div key={`additional_policy_fields_${index}`} className="text-row">
        <div
          dangerouslySetInnerHTML={{
            __html: content.field_title.html,
          }}
        />

        <div
          className="black-text"
          dangerouslySetInnerHTML={{
            __html: content.additional_policy_field.html,
          }}
        />
      </div>
    ));

  const pressFields = data.press_fields
    .filter(content => content.field_title.text !== "")
    .map((content, index) => (
      <div key={`press_fields_${index}`} className="text-row">
        <div
          dangerouslySetInnerHTML={{
            __html: content.field_title.html,
          }}
        />

        <div
          className="black-text"
          dangerouslySetInnerHTML={{
            __html: content.press_link.html,
          }}
        />
      </div>
    ));

  return (
    <Grid>
      <HouseDetailsTitle>
        <p className="transcript">{data.title.text} details</p>
      </HouseDetailsTitle>

      {(data.sleeps !== null ||
        data.bedrooms !== null ||
        data.floors !== null ||
        data.size.text !== "" ||
        data.architect.text !== "" ||
        data.year_built !== null ||
        data.notes.text !== "" ||
        additionalHouseFields.length >= 1) && (
        <Row className="house row">
          <AccordionTitle>
            <button
              onClick={() => {
                if (accordionValues.house === true) {
                  changeHandler({ house: false });
                } else {
                  changeHandler({ house: true });
                }
              }}
            >
              <h3 className="transcript">House</h3>
              <DownArrowIcon
                rotation={accordionValues.house === true ? `-90` : `90`}
              />
            </button>
          </AccordionTitle>

          <Accordion maxHeight={accordionValues.house === true ? `300px` : 0}>
            {data.sleeps !== null && (
              <div className="text-row">
                <p>Sleeps</p>
                <div className="black-text">{data.sleeps}</div>
              </div>
            )}

            {data.bedrooms !== null && (
              <div className="text-row">
                <p>Bedrooms</p>
                <div className="black-text">{data.bedrooms}</div>
              </div>
            )}

            {data.floors !== null && (
              <div className="text-row">
                <p>Floors</p>
                <div className="black-text">{data.floors}</div>
              </div>
            )}

            {data.size.text !== "" && (
              <div className="text-row">
                <p>Size</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.size.html,
                  }}
                />
              </div>
            )}

            {data.notes.text !== "" && (
              <div className="text-row">
                <p>Notes</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.notes.html,
                  }}
                />
              </div>
            )}

            {data.architect.text !== "" && (
              <div className="text-row">
                <p>Architect</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.architect.html,
                  }}
                />
              </div>
            )}

            {data.year_built !== null && (
              <div className="text-row">
                <p>Year completed</p>
                <div className="black-text">{data.year_built}</div>
              </div>
            )}

            {additionalHouseFields}
          </Accordion>
        </Row>
      )}

      {(data.area.text !== "" ||
        data.nearest_village.text !== "" ||
        data.nearest_city.text !== "" ||
        data.directions.text !== "" ||
        data.on_the_doorstep.text !== "" ||
        additionalLocationFields.length >= 1) && (
        <Row className="location row">
          <AccordionTitle>
            <button
              onClick={() => {
                if (accordionValues.location === true) {
                  changeHandler({ location: false });
                } else {
                  changeHandler({ location: true });
                }
              }}
            >
              <h3 className="transcript">Location</h3>
              <DownArrowIcon
                rotation={accordionValues.location === true ? `-90` : `90`}
              />
            </button>
          </AccordionTitle>

          <Accordion
            maxHeight={accordionValues.location === true ? `300px` : 0}
          >
            {data.area.text !== "" && (
              <div className="text-row">
                <p>Area</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.area.html,
                  }}
                />
              </div>
            )}
            {data.on_the_doorstep.text !== "" && (
              <div className="text-row">
                <p>On the doorstep</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.on_the_doorstep.html,
                  }}
                />
              </div>
            )}
            {data.nearest_village.text !== "" && (
              <div className="text-row">
                <p>Nearest village</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.nearest_village.html,
                  }}
                />
              </div>
            )}
            {data.nearest_city.text !== "" && (
              <div className="text-row">
                <p>Nearest city</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.nearest_city.html,
                  }}
                />
              </div>
            )}
            {data.directions.text !== "" && (
              <div className="text-row">
                <p>Directions</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.directions.html,
                  }}
                />
              </div>
            )}

            {additionalLocationFields}
          </Accordion>
        </Row>
      )}

      {(data.by_train !== "" ||
        data.by_car.text !== "" ||
        data.train_to_house_transport.text !== "" ||
        data.ev_hire.text !== "" ||
        data.ev_charging.text !== "" ||
        data.by_bicycle.text !== "" ||
        additionalJourneyFields.length >= 1) && (
        <Row className="journey row">
          <AccordionTitle>
            <button
              onClick={() => {
                if (accordionValues.journey === true) {
                  changeHandler({ journey: false });
                } else {
                  changeHandler({ journey: true });
                }
              }}
            >
              <h3 className="transcript">Journey</h3>
              <DownArrowIcon
                rotation={accordionValues.journey === true ? `-90` : `90`}
              />
            </button>
          </AccordionTitle>

          <Accordion maxHeight={accordionValues.journey === true ? `300px` : 0}>
            {data.by_train.text !== "" && (
              <div className="text-row">
                <p>By train</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.by_train.html,
                  }}
                />
              </div>
            )}

            {data.train_to_house_transport.text !== "" && (
              <div className="text-row">
                <p>Train to house transport</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.train_to_house_transport.html,
                  }}
                />
              </div>
            )}

            {data.by_car.text !== "" && (
              <div className="text-row">
                <p>By car</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.by_car.html,
                  }}
                />
              </div>
            )}

            {data.ev_hire.text !== "" && (
              <div className="text-row">
                <p>Electric vehicle hire</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.ev_hire.html,
                  }}
                />
              </div>
            )}

            {data.ev_charging.text !== "" && (
              <div className="text-row">
                <p>Electric vehicle charging</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.ev_charging.html,
                  }}
                />
              </div>
            )}

            {data.by_bicycle.text !== "" && (
              <div className="text-row">
                <p>By bicycle</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.by_bicycle.html,
                  }}
                />
              </div>
            )}

            {additionalJourneyFields}
          </Accordion>
        </Row>
      )}

      {additionalPriceFields.length >= 1 && (
        <Row className="prices row">
          <AccordionTitle>
            <button
              onClick={() => {
                if (accordionValues.prices === true) {
                  changeHandler({ prices: false });
                } else {
                  changeHandler({ prices: true });
                }
              }}
            >
              <h3 className="transcript">Prices</h3>
              <DownArrowIcon
                rotation={accordionValues.prices === true ? `-90` : `90`}
              />
            </button>
          </AccordionTitle>

          <Accordion maxHeight={accordionValues.prices === true ? `300px` : 0}>
            {additionalPriceFields}
          </Accordion>
        </Row>
      )}

      {(data.to_book.text !== "" ||
        data.booking_availability_text.text !== "" ||
        data.rental_type.text !== "" ||
        data.owners_website_field.text !== null ||
        additionalBookingFields.length >= 1) && (
        <Row className="booking row">
          <AccordionTitle>
            <button
              onClick={() => {
                if (accordionValues.booking === true) {
                  changeHandler({ booking: false });
                } else {
                  changeHandler({ booking: true });
                }
              }}
            >
              <h3 className="transcript">Booking</h3>
              <DownArrowIcon
                rotation={accordionValues.booking === true ? `-90` : `90`}
              />
            </button>
          </AccordionTitle>

          <Accordion maxHeight={accordionValues.booking === true ? `300px` : 0}>
            {data.to_book.text !== "" && (
              <div className="text-row">
                <p>To book</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.to_book.html,
                  }}
                />
              </div>
            )}

            {data.booking_availability_text.text !== "" && (
              <div className="text-row">
                <p>Availability</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.booking_availability_text.html,
                  }}
                />
              </div>
            )}

            {data.rental_type.text !== "" && (
              <div className="text-row">
                <p>Rental type</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.rental_type.html,
                  }}
                />
              </div>
            )}

            {data.owners_website_field.text !== null && (
              <div className="text-row">
                <p>Owner’s website</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.owners_website_field.html,
                  }}
                />
              </div>
            )}

            {additionalBookingFields}
          </Accordion>
        </Row>
      )}

      {pressFields.length >= 1 && (
        <Row className="press row">
          <AccordionTitle>
            <button
              onClick={() => {
                if (accordionValues.press === true) {
                  changeHandler({ press: false });
                } else {
                  changeHandler({ press: true });
                }
              }}
            >
              <h3 className="transcript">Press</h3>
              <DownArrowIcon
                rotation={accordionValues.press === true ? `-90` : `90`}
              />
            </button>
          </AccordionTitle>

          <Accordion maxHeight={accordionValues.press === true ? `300px` : 0}>
            {pressFields}
          </Accordion>
        </Row>
      )}

      {(data.covid_policy.text !== "" ||
        data.further_information.text !== "" ||
        additionalPolicyFields.length >= 1) && (
        <Row className="policy row">
          <AccordionTitle>
            <button
              onClick={() => {
                if (accordionValues.policy === true) {
                  changeHandler({ policy: false });
                } else {
                  changeHandler({ policy: true });
                }
              }}
            >
              <h3 className="transcript">Covid-19</h3>
              <DownArrowIcon
                rotation={accordionValues.policy === true ? `-90` : `90`}
              />
            </button>
          </AccordionTitle>

          <Accordion maxHeight={accordionValues.policy === true ? `300px` : 0}>
            {data.covid_policy.text !== "" && (
              <div className="text-row">
                <p>Policy</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.covid_policy.html,
                  }}
                />
              </div>
            )}

            {data.further_information.text !== "" && (
              <div className="text-row">
                <p>Further information</p>
                <div
                  className="black-text"
                  dangerouslySetInnerHTML={{
                    __html: data.further_information.html,
                  }}
                />
              </div>
            )}

            {additionalPolicyFields}
          </Accordion>
        </Row>
      )}
    </Grid>
  );
};
