import React, { useState } from "react";
import styled from "styled-components";
import ReactMapGL, { Marker, Popup, WebMercatorViewport } from "react-map-gl";

// Components
import { Navigation } from "./navigation";
import { MarkerIcon } from "./marker-icon";

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;

  margin: 120px 0 0 0;

  @media (max-width: 768px) {
    margin: 95px 0 0 0;
  }

  @media (max-width: 600px) {
    height: 350px;
  }

  & .mapboxgl-popup-tip {
    border: 0;
  }

  & .mapboxgl-popup-content {
    background: #9c381c;
    margin: 0 20px;

    padding: 5px 8px 2px 8px;
    box-shadow: none;
    border-radius: 0;
  }

  & .mapboxgl-popup.house-marker {
    & .mapboxgl-popup-content {
      background: #0e3563;
    }
  }

  & .mapboxgl-popup-close-button {
    display: none;
  }

  & a {
    text-decoration: none !important;
    border-bottom: 0 !important;

    &:hover {
      border-bottom: 0 !important;
    }
  }
`;

const PopupContainer = styled.div`
  color: #fff;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  & p {
    margin: 0;

    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.05em;

    text-align: center;

    @media (max-width: 600px) {
      font-size: 15px;
    }
  }
`;

const applyToArray = (func, array) => func.apply(Math, array);

const DesktopMap = ({
  listingLocation,
  activeMarker,
  setActiveMarker,
  favourites,
  allMapLocations,
  listingTitle,
  isHouseMarkerOpen,
  setIsHouseMarkerOpen,
}) => {
  const getBoundsForPoints = points => {
    // Calculate corner values of bounds
    const pointsLong = points.map(point => point.longitude);
    const pointsLat = points.map(point => point.latitude);
    const cornersLongLat = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 500,
    }).fitBounds(cornersLongLat, { padding: 50 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  };

  let mapPoints = favourites
    .filter(
      listing =>
        listing.location1.latitude !== undefined &&
        listing.location1.longitude !== undefined
    )
    .filter(
      listing =>
        listing.location1.latitude !== null &&
        listing.location1.longitude !== null
    )
    .map((listing, index) => {
      return {
        longitude: listing.location1.longitude,
        latitude: listing.location1.latitude,
      };
    });

  const origin = {
    latitude: listingLocation.latitude,
    longitude: listingLocation.longitude,
  };
  mapPoints.push(origin);

  const bounds = getBoundsForPoints(mapPoints);
  const [viewport, setViewport] = useState({
    ...bounds,
  });

  return (
    <MapContainer>
      {/* //https://medium.com/@weberzt/creating-a-multi-marker-map-using-mapbox-gl-js-105224ba3627 */}
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={nextViewport => setViewport(nextViewport)}
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX}
        mapStyle="mapbox://styles/covers/ckhyryqg4237n19qu0nsriw0c"
        scrollZoom={false}
        maxZoom={15}
        minZoom={4}
      >
        <Navigation />

        <Marker
          key={`listing_location_map_marker`}
          latitude={listingLocation.latitude}
          longitude={listingLocation.longitude}
        >
          <MarkerIcon
            bgColor={`#0e3563`}
            onClick={() => {
              setActiveMarker(null);
              setIsHouseMarkerOpen(!isHouseMarkerOpen);
            }}
          />
        </Marker>

        {isHouseMarkerOpen && (
          <Popup
            latitude={listingLocation.latitude}
            longitude={listingLocation.longitude}
            anchor="left"
            className="house-marker"
          >
            <PopupContainer>
              <p className="transcript">{listingTitle}</p>
            </PopupContainer>
          </Popup>
        )}

        {allMapLocations}

        {activeMarker !== null && (
          <Popup
            latitude={activeMarker.location1.latitude}
            longitude={activeMarker.location1.longitude}
            anchor="left"
          >
            <PopupContainer>
              <p className="transcript">{activeMarker.map_title}</p>
            </PopupContainer>
          </Popup>
        )}
      </ReactMapGL>
    </MapContainer>
  );
};

const MobileMap = ({
  listingLocation,
  activeMarker,
  setActiveMarker,
  favourites,
  allMapLocations,
  listingTitle,
  isHouseMarkerOpen,
  setIsHouseMarkerOpen,
}) => {
  const getBoundsForPoints = points => {
    // Calculate corner values of bounds
    const pointsLong = points.map(point => point.longitude);
    const pointsLat = points.map(point => point.latitude);
    const cornersLongLat = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 320,
      height: 350,
    }).fitBounds(cornersLongLat, { padding: 20 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  };

  let mapPoints = favourites
    .filter(
      listing =>
        listing.location1.latitude !== undefined &&
        listing.location1.longitude !== undefined
    )
    .filter(
      listing =>
        listing.location1.latitude !== null &&
        listing.location1.longitude !== null
    )
    .map((listing, index) => {
      return {
        longitude: listing.location1.longitude,
        latitude: listing.location1.latitude,
      };
    });

  const origin = {
    latitude: listingLocation.latitude,
    longitude: listingLocation.longitude,
  };
  mapPoints.push(origin);

  const bounds = getBoundsForPoints(mapPoints);
  const [viewport, setViewport] = useState({
    ...bounds,
  });

  return (
    <MapContainer>
      {/* //https://medium.com/@weberzt/creating-a-multi-marker-map-using-mapbox-gl-js-105224ba3627 */}
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={nextViewport => setViewport(nextViewport)}
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX}
        mapStyle="mapbox://styles/covers/ckhyryqg4237n19qu0nsriw0c"
        scrollZoom={false}
      >
        <Navigation />

        <Marker
          key={`listing_location_map_marker`}
          latitude={listingLocation.latitude}
          longitude={listingLocation.longitude}
        >
          <MarkerIcon
            bgColor={`#0e3563`}
            onClick={() => {
              setActiveMarker(null);
              setIsHouseMarkerOpen(!isHouseMarkerOpen);
            }}
          />
        </Marker>

        {isHouseMarkerOpen && (
          <Popup
            latitude={listingLocation.latitude}
            longitude={listingLocation.longitude}
            anchor="left"
            className="house-marker"
          >
            <PopupContainer>
              <p className="transcript">{listingTitle}</p>
            </PopupContainer>
          </Popup>
        )}

        {allMapLocations}

        {activeMarker !== null && (
          <Popup
            latitude={activeMarker.location1.latitude}
            longitude={activeMarker.location1.longitude}
            anchor="left"
          >
            <PopupContainer>
              <p className="transcript">{activeMarker.map_title}</p>
            </PopupContainer>
          </Popup>
        )}
      </ReactMapGL>
    </MapContainer>
  );
};

export const OurFavouritesMap = ({
  favourites,
  listingLocation,
  activeMarker,
  setActiveMarker,
  breakpoint,
  listingTitle,
}) => {
  const [isHouseMarkerOpen, setIsHouseMarkerOpen] = useState(false);

  const allMapLocations = favourites
    .filter(listing => listing.display_on_favourites_map !== false)
    .filter(
      listing =>
        listing.location1.latitude !== undefined &&
        listing.location1.longitude !== undefined
    )
    .filter(
      listing =>
        listing.location1.latitude !== null &&
        listing.location1.longitude !== null
    )
    .map((listing, index) => {
      return (
        <Marker
          key={`favourites_map_marker_${index}`}
          latitude={listing.location1.latitude}
          longitude={listing.location1.longitude}
        >
          <MarkerIcon
            bgColor={`#9c381c`}
            onClick={() => {
              setIsHouseMarkerOpen(false);
              if (activeMarker === listing) {
                setActiveMarker(null);
              }
              if (activeMarker === null) {
                setActiveMarker(listing);
              }
              if (activeMarker !== listing) {
                setActiveMarker(listing);
              }
            }}
          />
        </Marker>
      );
    });

  return (
    <>
      {breakpoint === "S" ? (
        <MobileMap
          listingLocation={listingLocation}
          activeMarker={activeMarker}
          setActiveMarker={setActiveMarker}
          favourites={favourites}
          allMapLocations={allMapLocations}
          listingTitle={listingTitle}
          isHouseMarkerOpen={isHouseMarkerOpen}
          setIsHouseMarkerOpen={setIsHouseMarkerOpen}
        />
      ) : (
        <DesktopMap
          listingLocation={listingLocation}
          activeMarker={activeMarker}
          setActiveMarker={setActiveMarker}
          favourites={favourites}
          allMapLocations={allMapLocations}
          listingTitle={listingTitle}
          isHouseMarkerOpen={isHouseMarkerOpen}
          setIsHouseMarkerOpen={setIsHouseMarkerOpen}
        />
      )}
    </>
  );
};
