import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Components
import { LinkIcon } from "../icons/link-arrow";

dayjs.extend(relativeTime);
const AvailabilityModule = styled.div`
  grid-column: span ${props => props.gridSpanDesktop};

  & .details {
    width: 100%;
    padding: 25px 25px 40px 25px;

    background-color: rgba(14, 99, 63, 0.06);
    color: rgba(34, 95, 24, 1);

    & h3 {
      margin: 0 0 1em 0;

      font-size: 20px;
      letter-spacing: 0.05em;

      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 22px;
      }
    }

    & p {
      margin: 0;

      font-size: 21px;
      line-height: 25px;
      letter-spacing: 0.03em;

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    & .disclaimer {
      margin: 1em 0 0 0;

      & p {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.03em;
      }
    }

    @media (max-width: 768px) {
      padding: 15px;
    }
  }

  & .booking-link {
    & a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      text-decoration: none;

      font-size: 20px;
      letter-spacing: 0.05em;

      padding: 20px 25px;

      background-color: #225f18;
      color: #fff;
      border-bottom: 0;

      transition: 250ms background-color ease;

      &:hover {
        background-color: rgb(34 95 24 / 90%);
        border-bottom: 0;
      }

      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 22px;

        padding: 15px;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 30px 0;

    width: 100%;
  }
`;

export const Availability = ({
  gridSpanDesktop,
  startDate,
  endDate,
  price,
  link,
  currency,
  availabilityText,
}) => {
  const now = dayjs();
  const availabilityStartDate = dayjs(startDate);
  const availabilityEndDate = dayjs(endDate);
  const totalNights = availabilityEndDate.diff(availabilityStartDate, "day");
  const timeUntilAvailable = dayjs().to(dayjs(availabilityStartDate));
  const availableSoon = availabilityStartDate.diff(now, "day");

  if (availableSoon >= 1) {
    const formattedPrice = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: currency,
      maximumSignificantDigits: Math.trunc(Math.abs(price)).toFixed().length,
    }).format(price);

    return (
      <AvailabilityModule
        gridSpanDesktop={gridSpanDesktop}
        className="availability-module"
      >
        <div className="details">
          <h3 className="transcript">It’s available {timeUntilAvailable}</h3>
          <p>
            {totalNights} night{totalNights > 1 && `s`}
          </p>
          <p>
            {availabilityStartDate.format("YYYY") ===
            availabilityEndDate.format("YYYY") ? (
              <>
                {availabilityStartDate.format("dddd D MMMM")} –{" "}
                {availabilityEndDate.format("dddd D MMMM YYYY")}
              </>
            ) : (
              <>
                {availabilityStartDate.format("dddd D MMMM YYYY")} –{" "}
                {availabilityEndDate.format("dddd D MMMM YYYY")}
              </>
            )}
          </p>
          <p>{formattedPrice} total</p>

          <div className="disclaimer">
            <p>{availabilityText}</p>
          </div>
        </div>
        <div className="booking-link">
          <a className="transcript" href={link} target="_blank">
            Book now <LinkIcon fill={`#fff`} />
          </a>
        </div>
      </AvailabilityModule>
    );
  } else {
    return null;
  }
};
