import React from "react";
import styled from "styled-components";

const Icon = styled.svg``;

export const AlertIcon = () => (
  <Icon
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#0E3563" fillOpacity="0.1" />
    <path
      d="M13 8.66V7H16.04V8.66L15.44 16.52H13.6L13 8.66ZM13 17.96H16.04V21H13V17.96Z"
      fill="#0E3563"
    />
  </Icon>
);
