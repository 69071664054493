import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import Masonry from "react-masonry-css";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";
import "../styles/modal.css";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

// Icons
import { Close, Next, Prev } from "../icons/slideshow-icons";

// Components

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    padding: "0",
    border: "0",
    borderRadius: "0",
  },
};

const MasonryContainer = styled.div`
  .my-masonry-grid {
    display: flex;
    margin-left: -60px;
    width: auto;

    @media (max-width: 1000px) {
      margin-left: -40px;
    }

    @media (max-width: 768px) {
      margin-left: -20px;
    }
  }
  .my-masonry-grid_column {
    padding-left: 60px;
    background-clip: padding-box;

    @media (max-width: 1000px) {
      padding-left: 40px;
    }

    @media (max-width: 768px) {
      padding-left: 20px;
    }
  }

  .my-masonry-grid_column > div {
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
`;

const GalleryTrigger = styled.div`
  position: relative;
  width: 100%;

  cursor: pointer;

  & button {
    width: 100%;
    height: 100%;

    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;

    border: 0;
    padding: 20px;
    margin: 0;

    color: #fff;
    background-color: #0e3563;

    transition: 250ms background-color ease;

    &:hover {
      background-color: rgb(14 53 99 / 90%);
    }

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
    }
  }
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0 0 90px 0;

  transition: 500ms opacity ease;
  opacity: ${props => props.opacity};

  & .carousel:focus,
  & .carousel:focus-within,
  & .carousel-root:focus,
  & .carousel-root:focus-within {
    outline: none;
  }

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .image-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }

  & img.landscape {
    padding: 90px 120px 0 120px;

    @media (max-width: 768px) {
      padding: 40px 40px 0 40px;
    }

    @media (max-width: 500px) {
      padding: 20px 20px 0 20px;
    }
  }

  & img.portrait {
    padding: 90px 180px 0 180px;

    @media (max-width: 700px) {
      padding: 40px 40px 0 40px;
    }

    @media (max-width: 500px) {
      padding: 20px 20px 0 20px;
    }
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & a {
    display: block;

    width: 100%;
    height: 100%;

    position: relative;
  }

  & img {
    max-width: 100%;

    object-fit: contain;
  }
`;

const Caption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;

  padding: 10px 90px;

  & p:first-of-type {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.03em;

    margin: 0 10px 0 0;
  }

  & p:last-of-type {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    margin: 0 0 0 10px;
  }

  @media (max-width: 768px) {
    padding: 10px 30px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 50px;
  right: 50px;

  border: 0;
  padding: 0;

  z-index: 500;

  @media (max-width: 1200px) {
    top: 40px;
    right: 40px;
  }

  @media (max-width: 768px) {
    top: 20px;
    right: 20px;
  }
`;

const ImageGallery = styled.div`
  width: 100%;
`;

const PreviewImage = styled.div`
  position: relative;
  width: 100%;

  cursor: pointer;

  & p {
    opacity: 0;
    transition: 250ms opacity ease;

    margin: 10px 0 0 0;

    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.03em;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    & p {
      opacity: 1;
    }
  }

  & .image-wrapper {
    width: 100%;
  }
`;

const SlideshowArrow = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;

  position: fixed;
  top: 50%;
  transform: translateY(-50%);

  left: ${props => props.left};
  right: ${props => props.right};

  z-index: 100;

  @media (max-width: 1200px) {
    left: ${props => props.leftMedium};
    right: ${props => props.rightMedium};
  }

  @media (max-width: 768px) {
    left: ${props => props.leftSmall};
    right: ${props => props.rightSmall};
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Gallery = ({ images, opacity }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(images.length);
    setAllGalleryContent(images);
  }, [images]);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const galleryContent = images
    .filter((content, index) => content.image.fluid !== null)
    .map((content, index) => {
      return (
        <ImageContainer key={`homepage_images_${index}`}>
          {content.image.fluid !== null && (
            <img
              className={ImageOrientation(content.image)}
              srcSet={content.image.fluid.srcSetWebp}
              src={content.image.fluid.srcWebp}
              alt={content.image.alt}
              loading={
                index <= 2 || index === images.length - 1 ? `eager` : `lazy`
              }
            />
          )}
        </ImageContainer>
      );
    });

  const listingImageGalleryPreviewImages = images
    .filter((content, index) => content.image.fluid !== null)
    .filter((content, index) => index <= 4);

  const trigger = [
    <GalleryTrigger key={`gallery_trigger`}>
      <AspectRatioImageContainer image={null} padding={66.667}>
        <button
          className="transcript"
          onClick={() => {
            setIsOpen(true);
            setCurrentSlide(galleryContent.length > 5 ? 5 : 0);
          }}
        >
          View all {totalSlides} images
        </button>
      </AspectRatioImageContainer>
    </GalleryTrigger>,
  ];

  const listingImageGalleryPreview = listingImageGalleryPreviewImages.map(
    (content, index) => (
      <PreviewImage
        key={`listing_image_${index}`}
        className={ImageOrientation(content.image)}
        onClick={() => {
          setIsOpen(true);
          setCurrentSlide(index);
        }}
      >
        <div className="image-wrapper">
          <img
            srcSet={content.image.fluid.srcSetWebp}
            src={content.image.fluid.srcWebp}
            alt={content.image.alt}
            loading="lazy"
          />
        </div>
        <p>
          <em>{content.image.alt}</em>
        </p>
      </PreviewImage>
    )
  );

  const galleryImagesWithTrigger = listingImageGalleryPreview.concat(trigger);

  // Masonry
  const breakpointColumnsObj = {
    default: 3,
    767: 2,
  };

  return (
    <>
      <ImageGallery>
        <MasonryContainer>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {galleryImagesWithTrigger}
          </Masonry>
        </MasonryContainer>
      </ImageGallery>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Listing Gallery"
        shouldFocusAfterRender={true}
      >
        <GalleryContainer opacity={opacity}>
          <CloseButton onClick={() => setIsOpen(false)}>
            <Close fill={`#0e3563`} />
          </CloseButton>
          <Carousel
            showThumbs={false}
            autoPlay={true}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            className="image-gallery"
            selectedItem={currentSlide}
            onChange={index => updateCurrentSlide(index)}
            stopOnHover={false}
            interval={10000}
            transitionTime={750}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <SlideshowArrow
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  left={`60px`}
                  right={`unset`}
                  leftMedium={`40px`}
                  rightMedium={`unset`}
                  leftSmall={`20px`}
                  rightSmall={`unset`}
                >
                  <Prev fill={`#0e3563`} />
                </SlideshowArrow>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <SlideshowArrow
                  right={`60px`}
                  left={`unset`}
                  rightMedium={`40px`}
                  leftMedium={`unset`}
                  rightSmall={`20px`}
                  leftSmall={`unset`}
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                >
                  <Next fill={`#0e3563`} />
                </SlideshowArrow>
              )
            }
          >
            {galleryContent}
          </Carousel>

          <Caption>
            <p>
              {allGalleryContent[currentSlide] !== undefined && (
                <em>{allGalleryContent[currentSlide].image.alt}</em>
              )}
            </p>
            <p className="transcript">
              {currentSlide + 1} / {totalSlides}
            </p>
          </Caption>
        </GalleryContainer>
      </Modal>
    </>
  );
};
