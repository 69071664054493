import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Components
import { Availability } from "./availability";
import { FindOutMore } from "./find-out-more";

dayjs.extend(relativeTime);

export const TopAvailabilityModule = ({ data }) => {
  const now = dayjs();
  const availabilityStartDate = dayjs(data.prismicListing.data.start_date);
  const availabilityEndDate = dayjs(data.prismicListing.data.end_date);
  const availableSoon = availabilityStartDate.diff(now, "day");

  if (availableSoon >= 1) {
    return (
      <Availability
        gridSpanDesktop={2}
        startDate={data.prismicListing.data.start_date}
        endDate={data.prismicListing.data.end_date}
        price={data.prismicListing.data.price}
        link={data.prismicListing.data.booking_link.url}
        currency={data.prismicListing.data.country.document.data.currency_code}
        availabilityText={data.prismicListing.data.availability_text.text}
      />
    );
  } else {
    return (
      <FindOutMore
        gridSpanDesktop={2}
        availabilityLink={data.prismicListing.data.availability_link.url}
        bookingLink={data.prismicListing.data.booking_link.url}
        ownersWebsiteLink={data.prismicListing.data.owners_website_link.url}
      />
    );
  }
};
