import React from "react";
import styled from "styled-components";

// Components
import { LinkIcon } from "../icons/link-arrow";

const FindOutMoreModule = styled.div`
  grid-column: span ${props => props.gridSpanDesktop};

  & .details {
    width: 100%;
    padding: 25px 25px 40px 25px;

    background-color: rgba(14, 53, 99, 0.1);
    color: rgba(14, 53, 99, 1);

    & h3 {
      margin: 0 0 1em 0;

      font-size: 20px;
      letter-spacing: 0.05em;

      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 22px;
      }
    }

    & p {
      margin: 0;

      font-size: 21px;
      line-height: 25px;
      letter-spacing: 0.03em;

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    & .disclaimer {
      margin: 1em 0 0 0;
    }

    @media (max-width: 768px) {
      padding: 15px;
    }
  }

  & .booking-link,
  & .owners-link {
    & a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      padding: 20px 25px;

      font-size: 20px;
      letter-spacing: 0.05em;

      background-color: #0e3563;
      color: #fff;
      border-bottom: 0;

      transition: 250ms background-color ease;

      &:hover {
        background-color: rgb(14 53 99 / 90%);
        border-bottom: 0;
      }

      text-decoration: none;

      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 22px;

        padding: 15px;
      }
    }
  }

  & .booking-link {
    margin: 0 0 5px 0;
  }

  @media (max-width: 768px) {
    margin: 0 0 30px 0;
  }
`;

export const FindOutMore = ({
  gridSpanDesktop,
  availabilityLink,
  ownersWebsiteLink,
}) => {
  return (
    <FindOutMoreModule
      gridSpanDesktop={gridSpanDesktop}
      className="availability-module"
    >
      <div className="details">
        <h3 className="transcript">Find out more</h3>

        <div className="disclaimer">
          <p>Learn more about this house and its availability.</p>
        </div>
      </div>

      <div className="booking-link">
        <a className="transcript" href={availabilityLink} target="_blank">
          Availability
          <LinkIcon fill={`#fff`} />
        </a>
      </div>

      <div className="owners-link">
        <a className="transcript" href={ownersWebsiteLink} target="_blank">
          Owner’s website
          <LinkIcon fill={`#fff`} />
        </a>
      </div>
    </FindOutMoreModule>
  );
};
